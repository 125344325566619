nav {
  background-color: #000;
  display: block;
  height: 2.25rem;
  overflow: hidden;
}

nav a {
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #000;
  color: #fff;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

nav a.selected {
  background-color: #fff;
  color: #000;
}

nav a:hover {
  background-color: #fff;
  color: #000;
}

.wrapper {
  height: 100%;
}
