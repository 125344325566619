#popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

#popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 30%;
}

#popup-content .headerPopup {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#popup-content .headerPopup h2 {
    margin: 0;
    font-weight: normal;
}

#popup-content .headerPopup button {
    margin: 0;
}

#popup-content .puntosNegocioPersonales,
#popup-content .puntosNegocioGrupales,
#popup-content .bloqueEstructuraLiderazgo {
    width: 60%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#popup-content .puntosNegocioPersonales div,
#popup-content .puntosNegocioGrupales div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#popup-content .puntosNegocioPersonales div p,
#popup-content .puntosNegocioGrupales div p {
    margin: 0;
}

#popup-content .bloqueTableUltimosDocumentos {
    overflow: auto;
    width: 90%;
    margin: auto;
}
#popup-content .bloqueTableUltimosDocumentos table {
    width: 100%;
    border: 1px solid #000;
}
#popup-content .bloqueTableUltimosDocumentos table th, td {
    width: 25%;
    text-align: center;
    vertical-align: top;
    border: 1px solid #000;
    border-spacing: 0;
}

#popup-content .bloqueEstructuraLiderazgo {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#close-popup {
    margin-top: 10px;
}


.orgchart{
    background-image: linear-gradient(90deg,whitesmoke 10%,rgba(0,0,0,0) 10%),linear-gradient(whitesmoke 10%,rgba(0,0,0,0) 10%);
}

.orgchart .node .title{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2px 5px;
    font-size: 0.45rem;
    text-align: center;
}
.orgchart .node {
    width: 180px;
}
.orgchart .nodes {
    justify-content: center;
}

.orgchart .node .title p{
    margin-bottom: 0;
}

.orgchart .active-partner .title {
    background-color: green;
    width: 100%;
    height: 30px;
}
.orgchart .active-partner .title span{
    width: 120px;
    text-wrap: wrap;
}

.orgchart .active-partner .content {
    border-color: green;
    height: 100px;
}

.orgchart .inactive-partner .title {
    background-color: red;
    width: 100%;
    height: 30px;
}
.orgchart .inactive-partner .title span{
    width: 120px;
    text-wrap: wrap;
}

.orgchart .inactive-partner .content {
    border-color: red;
}

#chart-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.orgchart .node .content{
    height: 40px;
}

.oci.fa-solid.fa-users.symbol{
    display: none;
}